import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import Nav from './componentry/Nav';
import Footer from './componentry/Footer';
import { slugify } from '../utils/slugify';

const client = createClient({
  space: 'q8tyy7apys6n',
  accessToken: 'bcvbgpSk2tlxz4rDLs2nKRiLUVPHnXEUpKDzqcq28O4',
  environment: 'master',
});

const BlogArchive = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'blogPage',
          order: '-sys.createdAt'
        });
        setPosts(response.items);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch blog posts');
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return (
    <div className="min-h-screen bg-[#F5F5FF] flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#2828f1]"></div>
    </div>
  );

  if (error) return (
    <div className="min-h-screen bg-[#F5F5FF] flex items-center justify-center text-gray-900">
      {error}
    </div>
  );

  return (
    <div className="bg-[#F5F5FF] min-h-screen">
        <Nav />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-40">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Our Blog
          </h1>
          <p className="text-xl text-gray-600">
            Insights and updates from the Oneventory team
          </p>
        </div>

        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post) => (
            <Link
              key={post.sys.id}
              to={`/blog/${slugify(post.fields.title)}`}
              className="bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-md transition-shadow"
            >
              {post.fields.image && (
                <div className="aspect-video w-full overflow-hidden">
                  <img
                    src={post.fields.image.fields.file.url}
                    alt={post.fields.title}
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-2">
                  {post.fields.title}
                </h2>
                <div className="text-gray-600">
                  {new Date(post.sys.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BlogArchive;