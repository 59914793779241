import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Mail, Phone, MapPin } from 'lucide-react';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer>
      {/* CTA Section */}
      <div className="bg-[#2828f1] py-24">
        <div className="max-w-7xl mx-auto px-4 md:px-8 lg:px-16">
          <div className="flex flex-col md:flex-row items-start justify-between gap-8">
            <div>
              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-4 text-left">
                Affordable inventory management for small businesses
              </h2>
              <p className="text-white/80 text-md mb-8 md:mb-0 text-left">
                Start managing your inventory the smart way
              </p>
            </div>
          </div>

        <Link 
          to="/signup" 
          className="bg-[#AEFA98] text-gray-900 px-6 py-3 rounded-full hover:bg-[#9DE589] transition-colors inline-flex items-center space-x-2 mt-8"
        >
          <span>Start our Free Trial</span>
          <ArrowRight className="w-5 h-5" />
        </Link>

        </div>

      </div>

      {/* Main Footer Content */}
      <div className="bg-gray-900" id='support'>
        <div className="max-w-7xl mx-auto px-4 md:px-8 lg:px-16 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            {/* Logo and About */}
            <div className="space-y-6">
              <div className={`text-2xl font-bold mr-12 text-white`}>
                <Link to="/">Oneventory</Link>
              </div>
              <p className="text-gray-400 text-left">
                Simple yet powerful inventory management for growing businesses.
              </p>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="font-semibold text-white mb-4 text-left">Quick Links</h3>
              <ul className="space-y-3 text-left text-gray-400 hover:text-white transition-colors">
                <li><HashLink to={"/#features"}>Features</HashLink></li>
                <li><HashLink to={"/#pricing"}>Pricing</HashLink></li>
                <li><HashLink to={"/#about"}>About</HashLink></li>
                <li><HashLink to={"/blog"}>Blog</HashLink></li>
                <li><HashLink to={"/contact"}>Contact</HashLink></li>
              </ul>
            </div>

            {/* Features */}
            <div>
              <h3 className="font-semibold text-white mb-4 text-left">Features</h3>
              <ul className="space-y-3 text-left">
                {['Inventory Management', 'Sales Orders', 'Purchase Orders', 'Reporting'].map((item) => (
                  <li key={item}>
                    <a href="#" className="text-gray-400 hover:text-white transition-colors">
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact Info */}
            <div>
              <h3 className="font-semibold text-white mb-4 text-left">Get in Touch</h3>
              <ul className="space-y-3 text-left">
                <li>
                  <a href="mailto:hello@oneventory.com" className="text-gray-400 hover:text-white transition-colors flex items-center gap-2">
                    <Mail className="w-4 h-4" />
                    hello@oneventory.app
                  </a>
                </li>
                <li>
                  <div className="text-gray-400 flex items-center gap-2">
                    <MapPin className="w-4 h-4" />
                    Sydney, Australia
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-16 pt-8 border-t border-gray-800">
            <div className="flex flex-col md:flex-row justify-between items-start gap-4">
              <p className="text-gray-400 text-sm">
                © {new Date().getFullYear()} Oneventory. All rights reserved.
              </p>
              <div className="flex gap-6">
                <a href="#" className="text-gray-400 hover:text-white text-sm">Privacy Policy</a>
                <a href="#" className="text-gray-400 hover:text-white text-sm">Terms of Service</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;