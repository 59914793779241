import React, { useState } from 'react';
import { auth, googleProvider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, AlertCircle } from 'lucide-react';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-[#2828f1] flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
        {/* Logo */}
        <div className="mb-8 text-center">
          <div className={`font-bold mb-40 text-white`}>
                  Oneventory
            </div>
          <h2 className="text-3xl font-bold text-white mb-2">Welcome back</h2>
          <p className="text-white/80">Sign in to continue to your account</p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg flex items-center gap-2 text-white">
            <AlertCircle className="w-5 h-5 text-red-500" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {/* Sign In Form */}
        <form onSubmit={handleSignIn} className="space-y-4 signin-white">
          <div className="relative flex items-center">
           
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"

              required
              className="w-full bg-white/10 border border-white/20 rounded-lg h-12 pl-10 pr-4 text-white placeholder-white focus:outline-none focus:border-[#AEFA98] transition-colors"
            />
          </div>

          <div className="relative flex items-center">
            
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
              className="w-full bg-white/10 border border-white/20 rounded-lg h-12 pl-10 pr-4 text-white  focus:outline-none focus:border-[#AEFA98] transition-colors"
            />
          </div>

          <div className="flex justify-end">
            <Link to="/forgot-password" className="text-sm text-white/80 hover:text-white">
              Forgot password?
            </Link>
          </div>

          <button
            type="submit"
            className="w-full bg-[#AEFA98] text-gray-900 h-12 rounded-lg font-medium hover:bg-[#9DE589] transition-colors"
          >
            Sign in
          </button>
        </form>

        {/* Divider */}
        <div className="relative my-8">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-white/20"></div>
          </div>
          <div className="relative flex justify-center text-sm my-2">
            <span className="px-2 bg-[#2828f1] text-white/60">Or continue with</span>
          </div>
        </div>

        {/* Google Sign In */}
        <button
          onClick={handleGoogleSignIn}
          className="w-full bg-white text-gray-900 h-12 rounded-lg font-medium hover:bg-gray-100 transition-colors flex items-center justify-center gap-2"
        >
          <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-5 h-5" />
          Sign in with Google
        </button>

        {/* Sign Up Link */}
        <p className="mt-8 text-center text-white/80">
          Don't have an account?{' '}
          <Link to="/signup" className="text-[#AEFA98] hover:text-[#9DE589]">
            Create one now
          </Link>
        </p>
      </div>
    </div>
  );
}

export default SignIn;