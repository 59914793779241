

import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, collection, getDocs } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { ChevronRight, Building2, AlertCircle, Mail, Lock, User, MapPin, CreditCard } from 'lucide-react';
import { createClient } from 'contentful';

const client = createClient({
  space: 'q8tyy7apys6n',
  accessToken: 'bcvbgpSk2tlxz4rDLs2nKRiLUVPHnXEUpKDzqcq28O4',
  environment: 'master',
});

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [abn, setAbn] = useState('');
  const [address, setAddress] = useState('');
  const [existingCompany, setExistingCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [testimonials, setTestimonials] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    fetchCompanies();
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'testimonials'
      });
      setTestimonials(response.items);
    } catch (err) {
      console.error('Failed to load testimonials:', err);
    }
  };

  const fetchCompanies = async () => {
    const companiesCollection = collection(db, 'companies');
    const companiesSnapshot = await getDocs(companiesCollection);
    const companiesList = companiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCompanies(companiesList);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Create user

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let companyId;

      if (existingCompany) {
        companyId = existingCompany;
      } else {
        // Create new company
        const newCompanyRef = doc(collection(db, 'companies'));
        await setDoc(newCompanyRef, {
          name: companyName,
          abn: abn,
          createdAt: new Date(),
          createdBy: user.uid,
          address: address,
          users: [user.uid]
        });
        companyId = newCompanyRef.id;
      }

      // Create user document
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        email,
        companyId,
        role: existingCompany ? 'user' : 'admin',
        address,
        status: existingCompany ? 'pending' : 'active'
      });

      navigate('/payment');
      
    } catch (error) {
      setError(error.message);
    }
  };

  // Split the form submission into steps
  const handleNextStep = (e) => {
    e.preventDefault();
    setStep(step + 1);
  };

  return (
    <div className="min-h-screen flex signin-white">
      {/* Left Side - Sign Up Form */}
      <div className="w-full md:w-1/2 bg-[#2828f1] p-8 lg:p-16 flex items-center justify-center overflow-y-auto">
        <div className="w-full max-w-md">
          {/* Logo and Header */}
          <div className={`text-2xl font-bold mr-12 text-white`}>
                Oneventory
          </div>
          
          <h2 className="text-3xl font-bold text-white mb-2">
            Start your free trial
          </h2>
          <p className="text-white/80 mb-8">
            Get started in minutes.
          </p>

          {/* Error Message */}
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-lg flex items-center gap-2 text-white">
              <AlertCircle className="w-5 h-5 text-red-500" />
              <p className="text-sm">{error}</p>
            </div>
          )}

          {/* Multi-step Form */}
          <form onSubmit={step === 3 ? handleSignUp : handleNextStep} className="space-y-4">
          {step === 1 && (
            <>
              <div className="relative">
                
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  required
                  className="w-full bg-white/10 border border-white/20 rounded-lg pl-10 pr-4 py-3 text-white placeholder-white/90 focus:outline-none focus:border-[#AEFA98] transition-colors"
                />
              </div>
              <div className="relative">
                
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  required
                  className="w-full bg-white/10 border border-white/20 rounded-lg pl-10 pr-4 py-3 text-white placeholder-white/50 focus:outline-none focus:border-[#AEFA98] transition-colors"
                />
              </div>
              <div className="relative">
                
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Work Email"
                  required
                  className="w-full bg-white/10 border border-white/20 rounded-lg pl-10 pr-4 py-3 text-white placeholder-white/50 focus:outline-none focus:border-[#AEFA98] transition-colors"
                />
              </div>
              <div className="relative">
                
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required
                  className="w-full bg-white/10 border border-white/20 rounded-lg pl-10 pr-4 py-3 text-white placeholder-white/50 focus:outline-none focus:border-[#AEFA98] transition-colors"
                />
              </div>
            </>
          )}    

            {step === 2 && (
              <>
                <select
                  value={existingCompany}
                  onChange={(e) => setExistingCompany(e.target.value)}
                  className="w-full bg-white/10 border border-white/20 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#AEFA98] transition-colors"
                >
                  <option value="">Create New Company</option>
                  {companies.map(company => (
                    <option key={company.id} value={company.id}>{company.name}</option>
                  ))}
                </select>

                {!existingCompany && (
                  <>
                    <div className="relative">
                      
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Company Name"
                        required
                        className="w-full bg-white/10 border border-white/20 rounded-lg px-10 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-[#AEFA98] transition-colors"
                      />
                    </div>
                    <div className="relative">
                      
                      <input
                        type="text"
                        value={abn}
                        onChange={(e) => setAbn(e.target.value)}
                        placeholder="ABN"
                        required
                        className="w-full bg-white/10 border border-white/20 rounded-lg px-10 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-[#AEFA98] transition-colors"
                      />
                    </div>
                    <div className="relative">
                      
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Business Address"
                        required
                        className="w-full bg-white/10 border border-white/20 rounded-lg px-10 py-3 text-white placeholder-gray-400 focus:outline-none focus:border-[#AEFA98] transition-colors"
                      />
                    </div>
                  </>
                )}
              </>
            )}

            <button
              type="submit"
              className="w-full bg-[#AEFA98] text-gray-900 py-3 rounded-lg font-medium hover:bg-[#9DE589] transition-colors flex items-center justify-center gap-2"
            >
              {step === 2 ? 'Create Account' : 'Continue'}
              <ChevronRight className="w-4 h-4" />
            </button>
          </form>

          {/* Form Progress */}
          <div className="flex justify-between mt-8">
            <div className={`h-1 w-1/2 rounded-full ${step >= 1 ? 'bg-[#AEFA98]' : 'bg-white/20'}`} />
            <div className={`h-1 w-1/2 rounded-full ml-2 ${step >= 2 ? 'bg-[#AEFA98]' : 'bg-white/20'}`} />
          </div>

          {/* Sign In Link */}
          <p className="mt-8 text-center text-white/80">
            Already have an account?{' '}
            <Link to="/signin" className="text-[#AEFA98] hover:text-[#9DE589]">
              Sign in
            </Link>
          </p>
        </div>
      </div>

      {/* Right Side - Testimonials */}
      <div className="hidden md:w-1/2 md:flex items-center justify-center bg-gray-50">
        <div className="max-w-lg p-8">
          {testimonials.length > 0 && (
            <div className="relative">
              <div className="mb-8">
                <div className="text-3xl font-medium mb-6">
                  "{testimonials[currentSlide]?.fields?.whatTheyHaveToSay?.content[0]?.content[0]?.value}"
                </div>
                <div className="font-medium">
                  {testimonials[currentSlide]?.fields?.title}
                </div>
                
              </div>

              {/* Navigation dots */}
              <div className="flex gap-2">
                {testimonials.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentSlide(index)}
                    className={`w-2 h-2 rounded-full transition-all
                      ${index === currentSlide 
                        ? 'bg-gray-900 w-4' 
                        : 'bg-gray-300 hover:bg-gray-400'
                      }`}
                    aria-label={`Go to slide ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;