import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import { Sparkle, ChevronLeft, ChevronRight } from 'lucide-react';

const client = createClient({
  space: 'q8tyy7apys6n',
  accessToken: 'bcvbgpSk2tlxz4rDLs2nKRiLUVPHnXEUpKDzqcq28O4',
  environment: 'master',
});

const TestimonialsSection = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'testimonials'
        });
        setTestimonials(response.items);
        setLoading(false);
      } catch (err) {
        setError('Failed to load testimonials');
        setLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => 
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => 
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  if (loading) {
    return (
      <div className="min-h-[400px] bg-[#2828f1] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[400px] bg-[#2828f1] flex items-center justify-center text-white">
        {error}
      </div>
    );
  }

  return (
    <section className="bg-[#2828f1] py-20 px-4 md:px-8 lg:px-16 relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0">
        <Sparkle className="w-24 h-24 text-[#AEFA98]" />
      </div>
      <div className="absolute bottom-0 left-0">
        <div className="w-64 h-64 border border-white/10 rounded-full translate-y-1/2 -translate-x-1/2"></div>
      </div>

      <div className="max-w-7xl mx-auto">
        {/* Heading */}
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-16">
          Built for the businesses
          <br />
          in between.
        </h2>

        {/* Testimonials Content */}
        <div className="relative">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            {/* Logo/Image */}
            <div className="bg-white/10 rounded-3xl aspect-square p-8 flex items-center justify-center">
              <img 
                src={testimonials[currentSlide]?.fields?.logo?.fields?.file?.url || '/placeholder.png'} 
                alt="Company logo" 
                className="max-w-full max-h-full object-contain"
              />
            </div>

            {/* Testimonial content */}
            <div className="text-white">
              <div className="text-1xl font-medium mb-6">
                "{testimonials[currentSlide]?.fields?.whatTheyHaveToSay?.content[0]?.content[0]?.value}"
              </div>
              <div className="mb-2 font-medium">
                {testimonials[currentSlide]?.fields?.title}
              </div>
              
            </div>
          </div>

          {/* Navigation Controls */}
          <div className="flex items-center justify-between mt-12">
            <div className="flex gap-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-2 h-2 rounded-full transition-all
                    ${index === currentSlide 
                      ? 'bg-white w-8' 
                      : 'bg-white/40 hover:bg-white/60'
                    }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
            
            <div className="flex gap-4">
              <button 
                onClick={prevSlide}
                className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                aria-label="Previous testimonial"
              >
                <ChevronLeft className="w-6 h-6 text-white" />
              </button>
              <button 
                onClick={nextSlide}
                className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                aria-label="Next testimonial"
              >
                <ChevronRight className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;