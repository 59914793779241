// components/BarcodeScanner.js
import React, { useState, useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';
import { Camera, X } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const BarcodeScanner = ({ companyId }) => {
  const [isScanning, setIsScanning] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [videoDevices, setVideoDevices] = useState([]);
  const [manualCode, setManualCode] = useState('');
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader());
  const navigate = useNavigate();

  // Handle hardware scanner input
  useEffect(() => {
    let barcodeBuffer = '';
    let lastKeyTime = Date.now();

    const handleKeyPress = async (e) => {
      const currentTime = Date.now();
      
      // If it's been more than 100ms since the last keystroke, reset the buffer
      if (currentTime - lastKeyTime > 100) {
        barcodeBuffer = '';
      }
      
      // Update the last key time
      lastKeyTime = currentTime;

      // If it's a Enter key and we have content in the buffer
      if (e.key === 'Enter' && barcodeBuffer) {
        await processBarcode(barcodeBuffer);
        barcodeBuffer = '';
        return;
      }

      // Add character to buffer
      if (e.key.length === 1) {
        barcodeBuffer += e.key;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [companyId]);

  // Initialize camera devices
  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevs = devices.filter(device => device.kind === 'videoinput');
        setVideoDevices(videoDevs);
        if (videoDevs.length > 0) {
          setSelectedDevice(videoDevs[0].deviceId);
        }
      } catch (err) {
        console.error('Error getting devices:', err);
        setError('Unable to access camera devices');
      }
    };

    getDevices();
    return () => {
      stopScanning();
    };
  }, []);

  const startScanning = async () => {
    try {
      setIsScanning(true);
      setError(null);
      
      const constraints = {
        video: {
          deviceId: selectedDevice ? { exact: selectedDevice } : undefined
        }
      };

      const controls = await codeReader.current.decodeFromConstraints(
        constraints,
        videoRef.current,
        async (result, error) => {
          if (result) {
            await processBarcode(result.getText());
          }
          if (error && error.name !== 'NotFoundException') {
            console.error('Scanning error:', error);
          }
        }
      );

      return () => {
        controls.stop();
      };
    } catch (err) {
      console.error('Error starting scanner:', err);
      setError('Failed to start scanner');
      setIsScanning(false);
    }
  };

  const stopScanning = () => {
    codeReader.current.reset();
    setIsScanning(false);
  };

  const processBarcode = async (code) => {
    try {
      // First try to find by barcode
      let inventoryQuery = query(
        collection(db, 'inventory'),
        where('companyId', '==', companyId),
        where('barcode', '==', code)
      );
      
      let querySnapshot = await getDocs(inventoryQuery);
      
      // If no results, try to find by SKU
      if (querySnapshot.empty) {
        inventoryQuery = query(
          collection(db, 'inventory'),
          where('companyId', '==', companyId),
          where('sku', '==', code)
        );
        querySnapshot = await getDocs(inventoryQuery);
      }

      if (!querySnapshot.empty) {
        const item = querySnapshot.docs[0];
        navigate(`/inventory/${item.id}`);
      } else {
        setError(`No item found with code: ${code}`);
      }
    } catch (err) {
      console.error('Error processing barcode:', err);
      setError('Failed to process barcode');
    }
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    if (manualCode) {
      await processBarcode(manualCode);
      setManualCode('');
    }
  };

  return (
    <div className="max-w-xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4">Barcode Scanner</h2>
        
        {/* Manual Entry Form */}
        <form onSubmit={handleManualSubmit} className="mb-6">
          <div className="flex gap-2">
            <input
              type="text"
              value={manualCode}
              onChange={(e) => setManualCode(e.target.value)}
              placeholder="Enter barcode or SKU manually"
              className="flex-1 p-2 border rounded"
              autoComplete="off"
            />
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Search
            </button>
          </div>
        </form>

        {/* Camera Controls */}
        <div className="mb-4">
          <select
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="w-full p-2 border rounded mb-2"
          >
            {videoDevices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </option>
            ))}
          </select>

          <button
            onClick={isScanning ? stopScanning : startScanning}
            className={`w-full p-2 rounded flex items-center justify-center gap-2 ${
              isScanning 
                ? 'bg-red-500 hover:bg-red-600' 
                : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            {isScanning ? (
              <>
                <X size={20} />
                Stop Scanner
              </>
            ) : (
              <>
                <Camera size={20} />
                Start Scanner
              </>
            )}
          </button>
        </div>

        {/* Video Preview */}
        {isScanning && (
          <div className="relative aspect-video bg-gray-100 rounded overflow-hidden">
            <video
              ref={videoRef}
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        )}

        {/* Error Display */}
        {error && (
          <div className="mt-4 p-3 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default BarcodeScanner;