import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { CheckCircle, Play } from 'lucide-react';

const FeatureCheck = ({ text }) => (
  <div className="flex items-start gap-3 mb-4">
    <CheckCircle className="w-5 h-5 text-white flex-shrink-0 mt-1" />
    <span className="text-white">{text}</span>
  </div>
);

const MetricCard = ({ label, value, change, period }) => (
  <div className="bg-blue-600 text-white p-6 rounded-xl">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-white/80 mb-1">{label}</p>
        <p className="text-2xl font-bold">${value.toLocaleString()}</p>
      </div>
      <div className="text-right">
        <p className="text-[#AEFA98] flex items-center gap-1">
          ↑ {change}%
        </p>
        <p className="text-sm text-white/60">{period}</p>
      </div>
    </div>
  </div>
);

const AnalyticsSection = () => {
  const chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    colors: ['#4F46E5', '#E11D48'],
    grid: {
      borderColor: '#f1f1f1',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      labels: {
        style: {
          colors: '#64748b'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#64748b'
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      theme: 'dark'
    }
  };

  const chartSeries = [
    {
      name: 'Revenue',
      data: [650, 550, 820, 600, 650, 600]
    },
    {
      name: 'Cost of Goods',
      data: [400, 500, 680, 610, 590, 580]
    }
  ];

  return (
    <section className="py-40 px-4 lg:px-18">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Left Column */}
          

          {/* Right Column */}
          <div className="space-y-16">
            {/* Feature List */}
            <div>
              <h2 className="text-4xl text-white font-bold mb-8">
                One Centralised Location for your Inventory
              </h2>
              <div className="space-y-4 text-white-600">
                <FeatureCheck text="Real time inventory & order tracking" />
                <FeatureCheck text="Always know what's in stock, what's running low, and what's on the way. No refresh needed." />
                <FeatureCheck text="From purchase orders to sales fulfillment, keep your entire inventory flow in one place." />
              </div>
            </div>

            {/* Xero Integration */}
            <div>
              <h4 className="text-4xl text-white font-bold mb-4">
                Integrates directly with Xero
              </h4>
              <p className="text-white  mb-6">
                With our native Xero integration, you can send your invoices across with ease.
              </p>
              
            </div>
            
          </div>

          <div>
            {/* Chart Card */}
            <div className="bg-white p-6 rounded-3xl shadow-lg mb-6">
              <h3 className="text-xl font-semibold mb-4">Sales Report</h3>
              <div className="h-64">
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="line"
                  height="100%"
                />
              </div>
            </div>

            {/* Metrics Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <MetricCard 
                label="Revenue"
                value={31092}
                change={4.2}
                period="from last year"
              />
              <MetricCard 
                label="Cost of Goods"
                value={24562}
                change={2.1}
                period="from last year"
              />
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default AnalyticsSection;