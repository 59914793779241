import React, { useState } from 'react';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Check, AlertCircle, Loader2, CreditCard } from 'lucide-react';
// In your Payment component:
import { SUBSCRIPTION_ROUTES, TRIAL_PERIOD_DAYS } from '../utils/subscription/constants';

const Payment = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const createCheckoutSession = async () => {
    if (!currentUser) {
      setError('Please sign in to continue');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // First, verify we can create the path correctly
      const customerPath = `customers/${currentUser.uid}/checkout_sessions`;
      console.log('Creating checkout session at path:', customerPath);

      const checkoutSessionRef = collection(db, 'customers', currentUser.uid, 'checkout_sessions');

      // Log the session data we're about to create
      const sessionData = {
        price: 'price_1Q9H5RGS89Y15uQTo2wb7e10', // Replace with your actual Stripe Price ID
        success_url: 'https://oneventory.app//subscription/success',
        cancel_url: 'https://oneventory.app//subscription/error',
        allow_promotion_codes: true,
        mode: 'subscription',
        metadata: {
          userId: currentUser.uid,
        },
        currency: 'AUD'
      };

      console.log('Creating session with data:', sessionData);

      const docRef = await addDoc(checkoutSessionRef, sessionData);
      console.log('Checkout session doc created:', docRef.id);

      // Set a timeout to prevent infinite loading
      const timeoutId = setTimeout(() => {
        setLoading(false);
        setError('Checkout session creation timed out. Please try again.');
      }, 10000);

      // Listen for changes
      const unsubscribe = onSnapshot(docRef, 
        (snap) => {
          const data = snap.data();
          console.log('Snapshot data:', data);

          if (data) {
            clearTimeout(timeoutId);
            
            if (data.error) {
              console.error('Checkout session error:', data.error);
              setError(data.error.message);
              setLoading(false);
            }

            if (data.url) {
              console.log('Redirecting to:', data.url);
              window.location.assign(data.url);
            }
          }
        },
        (error) => {
          console.error('Snapshot error:', error);
          clearTimeout(timeoutId);
          setError('Failed to monitor checkout session. Please try again.');
          setLoading(false);
        }
      );

      // Clean up listener if component unmounts
      return () => {
        unsubscribe();
        clearTimeout(timeoutId);
      };

    } catch (err) {
      console.error('Error creating checkout session:', err);
      setError('Failed to initiate checkout. Please check console for details.');
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-[#2828f1] flex items-center justify-center mt-10 p-4 md:p-8">
      {/* Main Card */}
      <div className="bg-white rounded-2xl w-full max-w-3xl shadow-2xl overflow-hidden">
        {/* Header Section */}
        <div className="p-8 border-b border-gray-100">
          <img 
            src="https://oneventory.app/ONEV_nobg.png" 
            alt="Oneventory Logo" 
            className="h-6 mb-8"
          />
          <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">
            Complete Your Subscription
          </h1>
          <p className="text-gray-600">
            Start managing your inventory the smart way with Oneventory
          </p>
        </div>

        {/* Error Message */}
        {error && (
          <div className="mx-8 my-4 p-4 bg-red-50 border border-red-100 rounded-lg flex items-center gap-2 text-red-600">
            <AlertCircle className="w-5 h-5 shrink-0" />
            <div>
              <p className="text-sm">{error}</p>
              {loading && <p className="text-sm mt-1">You can refresh the page and try again.</p>}
            </div>
          </div>
        )}

        {/* Plan Details */}
        <div className="p-8">
          <div className="bg-[#F5F5FF] rounded-lg p-6 mb-8">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h2 className="text-2xl font-bold text-gray-900 mb-1">Professional Plan</h2>
                <p className="text-[#2828f1] font-medium">10 Days Free Trial Included</p>
              </div>
              <div className="text-right">
                <div className="text-3xl font-bold text-gray-900">$89</div>
                <div className="text-gray-600 text-sm">/month AUD</div>
              </div>
            </div>
            <p className="text-gray-600 text-sm">
              Start your 10-day free trial today. No charges until your trial ends.
            </p>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 gap-4 mb-8">
            {[
              'Unlimited inventory items',
              'Unlimited sales orders',
              'Unlimited purchase orders',
              'Xero integration',
              'Priority support',
              'Real-time updates'
            ].map((feature, index) => (
              <div key={index} className="flex items-center gap-3">
                <div className="w-5 h-5 rounded-full bg-[#F5F5FF] flex items-center justify-center">
                  <Check className="w-4 h-4 text-[#2828f1]" />
                </div>
                <span className="text-gray-700">{feature}</span>
              </div>
            ))}
          </div>

          {/* Action Section */}
          <div className="space-y-4">
            <button
              onClick={createCheckoutSession}
              disabled={loading}
              className="w-full bg-[#AEFA98] text-gray-900 py-4 px-6 rounded-lg font-medium hover:bg-[#9DE589] transition-colors flex items-center justify-center gap-2 disabled:opacity-70 disabled:cursor-not-allowed"
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <CreditCard className="w-5 h-5" />
                  Start Free Trial
                </>
              )}
            </button>

            {loading && (
              <p className="text-gray-600 text-sm text-center">
                Creating your checkout session... This may take up to 10 seconds. 
              </p>
            )}
          </div>
        </div>

        {/* Footer */}
        <div className="bg-gray-50 p-8 border-t border-gray-100">
          <div className="flex items-center justify-between">
            <p className="text-gray-600 text-sm">
              Cancel anytime during the trial period
            </p>
            <p className="text-gray-600 text-sm flex items-center gap-1">
              🔒 Secure checkout powered by Stripe
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;