import React from 'react';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';

const PricingFeature = ({ text }) => (
  <div className="flex items-center gap-3 text-white/90">
    <Check className="w-5 h-5 text-[#AEFA98]" />
    <span>{text}</span>
  </div>
);

const PricingSection = () => {
  const features = [
    'Unlimited SKUs',
    'Unlimited Integrations',
    'Unlimited Locations',
    'Unlimited GMV',
    'Sales & Purchase Orders',
    'Reporting',
    'Up to 10 users',
    'Chat support'
  ];

  return (
    <section className="py-20 px-4 md:px-8 lg:px-16 bg-white" id='pricing'>
      <div className="max-w-7xl mx-auto">
        <div className="text-center max-w-5xl mx-auto mb-16">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
            If you're doing big things,
            but don't need a big cumbersome ERP 🙅
          </h2>
          <p className="text-gray-600">
            Get started today. We're ready!
          </p>
        </div>

        <div className="flex justify-center">
          <div className="bg-[#2828f1] rounded-3xl overflow-hidden max-w-md w-full relative">
            {/* Popular tag */}
            <div className="absolute top-4 right-4">
              <div className="bg-[#AEFA98] text-gray-900 px-4 py-1 rounded-full text-sm font-medium">
                Popular
              </div>
            </div>

            {/* Card content */}
            <div className="p-8">
              {/* Plan name */}
              <div className="text-white/80 mb-4">
                GOLD
              </div>

              {/* Price */}
              <div className="flex items-baseline mb-8">
                <span className="text-4xl font-bold text-white">$89</span>
                <span className="text-white/60 ml-2">/month</span>
                <span className="text-white/50">AUD</span>
              </div>

              {/* Features */}
              <div className="space-y-4 mb-8">
                {features.map((feature, index) => (
                  <PricingFeature key={index} text={feature} />
                ))}
              </div>

              {/* CTA Button */}
              <Link to="/signup" className="bg-[#AEFA98] text-gray-900 px-6 py-3 rounded-full hover:bg-[#9DE589] transition-colors inline-flex items-center space-x-2 mt-8">
                Sign Up Today
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;