import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';
import './styles/index.css';
import { Helmet } from 'react-helmet-async';
import HeroSection from './componentry/HeroSection';
import FeaturesSection from './componentry/FeaturesSection';
import Footer from './componentry/Footer';
import AnalyticsSection from './componentry/AnalyticsSection';
import PricingSection from './componentry/PricingSection';
import TestimonialsSection from './componentry/TestimonialsSection';
import FeaturesBenefitsSection from './componentry/FeaturesBenefitSection';
import MissionUSPSection from './componentry/MissionUSPSection';
import FAQSection from './componentry/FAQSection';

const client = createClient({
  space: 'q8tyy7apys6n',
  environment: 'master',
  accessToken: 'bcvbgpSk2tlxz4rDLs2nKRiLUVPHnXEUpKDzqcq28O4'
});

const Homepage = () => {
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const entry = await client.getEntry('2Kts9jmOQSoozWWBkEObdT');
        setContent(entry.fields);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch content. Please try again later.');
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div/>
    );
  }

  const renderRichText = (content) => {
    if (!content || !content.content) return null;
    
    return content.content.map((item, index) => {
      if (item.nodeType === 'paragraph') {
        return (
          <p key={index} className="mt-4 text-lg text-gray-700">
            {item.content.map((textNode, i) => textNode.value)}
          </p>
        );
      }
      return null;
    });
  };

  return (
    <div className="frontend">

<Helmet>
        {/* Primary Meta Tags */}
        <title>Oneventory | Cloud Based Inventory Management</title>
        <meta name="description" content="Streamline your inventory management with Oneventory's powerful analytics platform. Real-time tracking, predictive analytics, and automated reordering for optimized supply chain operations." />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oneventory.app" />
        <meta property="og:title" content="Oneventory | Modern Inventory Management & Analytics Platform" />
        <meta property="og:description" content="Streamline your inventory management with Oneventory's powerful analytics platform. Real-time tracking, predictive analytics, and automated reordering for optimized supply chain operations." />
        <meta property="og:image" content="https://oneventory.app/ONEV_nobg.png" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://oneventory.app" />
        <meta name="twitter:title" content="Oneventory | Modern Inventory Management & Analytics Platform" />
        <meta name="twitter:description" content="Streamline your inventory management with Oneventory's powerful analytics platform. Real-time tracking, predictive analytics, and automated reordering for optimized supply chain operations." />
        <meta name="twitter:image" content="https://oneventory.app/ONEV_nobg.png" />

        {/* Additional SEO Meta Tags */}
        <meta name="keywords" content="inventory management software, supply chain analytics, warehouse management system, stock optimization, inventory tracking, business analytics, predictive analytics, automated reordering, Oneventory" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Oneventory" />
        <link rel="canonical" href="https://oneventory.app" />

        {/* Additional Meta Tags for Rich Snippets */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SoftwareApplication",
              "name": "Oneventory",
              "applicationCategory": "BusinessApplication",
              "description": "Streamline your inventory management with One.Ventory's powerful analytics platform. Real-time tracking, predictive analytics, and automated reordering to optimize your supply chain operations.",
              "operatingSystem": "Web",
              "offers": {
                "@type": "Offer",
                "price": "89",
                "priceCurrency": "AUD"
              }
            }
          `}
        </script>
      </Helmet>

        <HeroSection />
        <FeaturesBenefitsSection/>
        <AnalyticsSection/>
        <PricingSection />
        <TestimonialsSection />
        <MissionUSPSection />
        <FAQSection />
        <Footer />
    </div>
  );
};

export default Homepage;