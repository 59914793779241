import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-6 flex justify-between items-center text-left hover:opacity-75 transition-opacity"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-medium text-gray-900">{question}</h3>
        {isOpen ? (
          <Minus className="w-5 h-5 text-[#2828f1] flex-shrink-0" />
        ) : (
          <Plus className="w-5 h-5 text-[#2828f1] flex-shrink-0" />
        )}
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? 'max-h-96 mb-6' : 'max-h-0'
        }`}
      >
        <p className="text-gray-600">{answer}</p>
      </div>
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "How does Oneventory compare to using spreadsheets?",
      answer: "Unlike spreadsheets, Oneventory offers real-time updates, automated tracking, and seamless integration with tools like Xero. You'll get more robust features than spreadsheets while maintaining simplicity, eliminating manual data entry errors, and saving valuable time."
    },
    {
      question: "Do you integrate with accounting and e-commerce platforms?",
      answer: "Yes! We currently integrate with Xero for seamless accounting synchronization, and we're adding WooCommerce and Shopify integrations soon. This means you can automatically sync your inventory, sales, and accounting data without double entry."
    },
    {
      question: "Is Oneventory suitable for my small business?",
      answer: "If you have 2-50 employees, manage physical inventory, and are looking for something more powerful than spreadsheets but less complex than an ERP, Oneventory is perfect for you. We're specifically designed for small businesses in retail, e-commerce, and wholesale sectors."
    },
    {
      question: "Can I access my inventory data from anywhere?",
      answer: "Absolutely! Oneventory is cloud-based, meaning you can access your real-time inventory data from any device with an internet connection. Stay in control of your stock levels whether you're in the store, at home, or on the go."
    },
    {
      question: "How long does it take to get started?",
      answer: "Our user-friendly interface is designed for quick adoption. You won't face steep learning curves or complex setups. Most businesses can get up and running in a matter of hours, not weeks, and our intuitive platform means minimal training time for your team."
    },
    {
      question: "What makes Oneventory different from expensive ERP systems?",
      answer: "We provide professional inventory management features at a small-business-friendly price. You get the essential tools you need - inventory tracking, sales orders, purchase orders, and integrations - without the overwhelming complexity and high costs associated with ERP systems."
    }
  ];

  return (
    <section className="bg-[#F5F5FF] py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600">
            Everything you need to know about Oneventory and how it can help your business
          </p>
        </div>

        <div className="bg-white rounded-2xl p-6 shadow-sm space-y-2">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;