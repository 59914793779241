import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import XeroConnect from "./XeroConnect";
import { ErrorBoundary } from 'react-error-boundary';
import StockSettings from './settings/StockSettings';
import getCompanyIdForUser from "../utils/getCompanyIdForUser";
import SubscriptionManagement from './SubscriptionManagement';
import ShopifyConnect from './ShopifyConnect';
//'./subscription/SubscriptionManagement';

const MyAccount = () => {
  const { currentUser } = useAuth();
  // Add state for tab index
  const [companyId, setCompanyId] = useState(null);
  const [userData, setUserData] = useState({
    displayName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    address: '',
    companyId: ''
  });
  const [companyData, setCompanyData] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    taxNumber: '',
    website: '',
    logo: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [tabIndex, setTabIndex] = useState(() => {
    return parseInt(localStorage.getItem('accountTabIndex') || '0');
  });

  useEffect(() => {
    const initializeData = async () => {
      if (!currentUser) return;

      setLoading(true);
      try {
        // First get company ID
        const id = await fetchCompanyId();
        if (id) {
          // Then fetch user and company data
          await Promise.all([
            fetchUserData(),
            fetchCompanyData(id)
          ]);
        }
      } catch (err) {
        console.error("Error initializing data:", err);
        setError("Failed to load account data");
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [currentUser]);

   // Data Fetching Functions
   const fetchCompanyId = async () => {
    try {
      const id = await getCompanyIdForUser(currentUser.uid);
      if (id) {
        setCompanyId(id);
        return id; // Return the id for immediate use
      } else {
        setError("No company ID found for user");
        return null;
      }
    } catch (err) {
      console.error("Error fetching company ID:", err);
      setError("Failed to fetch company ID");
      return null;
    }
  };

  const fetchUserData = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        const data = userDoc.data();
        setUserData({
          displayName: currentUser.displayName || '',
          email: currentUser.email || '',
          phoneNumber: data.phoneNumber || '',
          companyName: data.companyName || '',
          address: data.address || '',
          companyId: data.companyId || ''
        });
        return data.companyId; // Return companyId for immediate use
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to load user data");
    }
    return null;
  };

  const fetchCompanyData = async (id) => {
    if (!id) {
      console.log("No company ID provided to fetchCompanyData");
      return;
    }
    
    try {
      const companyDoc = await getDoc(doc(db, 'companies', id));
      if (companyDoc.exists()) {
        setCompanyData(companyDoc.data());
      } else {
        console.log("No company document found for ID:", id);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
      setError("Failed to load company data");
    }
  };

  const ErrorFallback = ({ error }) => (
    <div className="error-container">
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={() => window.location.reload()}>Refresh Page</button>
    </div>
  );


  

  const handleTabSelect = (index) => {
    setTabIndex(index);
    localStorage.setItem('accountTabIndex', index.toString());
  };

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await updateProfile(auth.currentUser, { displayName: userData.displayName });
      await setDoc(doc(db, 'users', currentUser.uid), {
        phoneNumber: userData.phoneNumber,
        address: userData.address,
      }, { merge: true });
      setIsEditing(false);
    } catch (error) {
      setError("Failed to update profile");
    }
  };

  const handleCompanyUpdate = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await setDoc(doc(db, 'companies', currentUser.companyId), companyData, { merge: true });
      setIsEditing(false);
    } catch (error) {
      setError("Failed to update company settings");
    }
  };

  const UserProfileTab = () => (
    <div className="tab-content">
      <h2>User Profile</h2>
      {currentUser.photoURL && (
        <div className="profile-photo">
          <img src={currentUser.photoURL} alt="Profile" />
        </div>
      )}
      
      {isEditing ? (
        <form onSubmit={handleUserUpdate}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={userData.displayName}
              onChange={(e) => setUserData({...userData, displayName: e.target.value})}
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="tel"
              value={userData.phoneNumber}
              onChange={(e) => setUserData({...userData, phoneNumber: e.target.value})}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <textarea
              value={userData.address}
              onChange={(e) => setUserData({...userData, address: e.target.value})}
            />
          </div>
          <div className="button-group">
            <button type="submit" className="primary-button">Save Changes</button>
            <button 
              type="button" 
              onClick={() => setIsEditing(false)}
              className="secondary-button"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div className="profile-info">
          <p><strong>Name:</strong> {userData.displayName}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Phone:</strong> {userData.phoneNumber || 'Not provided'}</p>
          <p><strong>Address:</strong> {userData.address || 'Not provided'}</p>
          <button 
            onClick={() => setIsEditing(true)}
            className="primary-button"
          >
            Edit Profile
          </button>
        </div>
      )}
    </div>
  );

  const CompanySettingsTab = () => (
    <div className="tab-content">
      <h2>Company Settings</h2>
      <form onSubmit={handleCompanyUpdate}>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            readonly="readonly"
            value={companyData.name}
            onChange={(e) => setCompanyData({...companyData, name: e.target.value})}
          />
        </div>
        <div className="form-group">
          <label>ABN</label>
          <input
            type="text"
            readonly="readonly"
            value={companyData.abn}
            onChange={(e) => setCompanyData({...companyData, abn: e.target.value})}
          />
        </div>

        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            readonly="readonly"
            value={companyData.address}
            onChange={(e) => setCompanyData({...companyData, address: e.target.value})}
          />
        </div>
        
        <div className="form-group">
          <label>Users</label>
          {companyData.users}
        </div>
        <button type="submit" className="primary-button" readonly="readonly">
          Save Company Settings
        </button>
      </form>
    </div>
  );

  const IntegrationsTab = () => (
    <div className="tab-content">
      <h2>Integrations</h2>
      <div className="integration-card">
        <h3>Xero Integration</h3>
        <div className="integration-status">
          <h4>Connection Status</h4>
          <XeroConnect />
        </div>
      </div>

      <ShopifyConnect />

    </div>
  );

  const SubscriptionsTab = () => (
    <div className="tab-content">
      <h2>Subscriptions</h2>
      <div className="subscription-card">
        <h3>Current Plan</h3>
        <SubscriptionManagement />
      </div>
    </div>
  );

  return (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="">
        <h1 className="text-xl font-semibold my-8">My Account</h1>
        
        {error && (
          <div className="error-message">
            {error}
          </div>
        )}

        {loading ? (
          <div>Loading...</div>
        ) : (
          <Tabs 
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            className="react-tabs"
            selectedTabClassName="is-selected"
          >
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Company</Tab>
          <Tab>Integrations</Tab>
          <Tab>Subscriptions</Tab>
        </TabList>

        <TabPanel>
          <UserProfileTab />
        </TabPanel>

        <TabPanel>
          <CompanySettingsTab />
        </TabPanel>

        <TabPanel>
          <IntegrationsTab />
        </TabPanel>

        <TabPanel>
          <SubscriptionsTab />
        </TabPanel>
        </Tabs>
        )}
      </div>
  </ErrorBoundary>
  );
  
};

export default MyAccount;