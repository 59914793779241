import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Nav = () => {
  const location = useLocation();
  const [isLightBg, setIsLightBg] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Determine which pages should use light background navigation
  useEffect(() => {
    const lightBgRoutes = ['/blog', '/features', '/about', '/contact'];
    setIsLightBg(lightBgRoutes.some(route => location.pathname.startsWith(route)));
  }, [location]);

  // Dynamic classes based on background
  const textClass = isLightBg ? 'text-gray-900' : 'text-white';
  const textHoverClass = isLightBg ? 'hover:text-gray-700' : 'hover:text-white';
  const textFadedClass = isLightBg ? 'text-gray-600' : 'text-white/90';
  const buttonBgClass = isLightBg ? 'bg-[#2828f1] text-white hover:bg-[#2020d0]' : 'bg-[#AEFA98] text-gray-900 hover:bg-[#9DE589]';

  return (
    <>
      <nav className="w-full px-4 py-4 md:px-8 lg:px-16 relative z-50">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            {/* Logo */}
            <div className={`${textClass} text-2xl font-bold mr-12`}>
              <Link to="/">Oneventory</Link>
            </div>
            
            {/* Desktop Navigation Links */}
            <div className="hidden md:flex space-x-8">
              <Link to="#features" className={`${textFadedClass} ${textHoverClass} transition-colors`}>Features</Link>
              <Link to="#pricing" className={`${textFadedClass} ${textHoverClass} transition-colors`}>Pricing</Link>
              <Link to="#about" className={`${textFadedClass} ${textHoverClass} transition-colors`}>About</Link>
              <Link to="/contact" className={`${textFadedClass} ${textHoverClass} transition-colors`}>Contact</Link>
              <Link to="/blog" className={`${textFadedClass} ${textHoverClass} transition-colors`}>Blog</Link>
            </div>
          </div>
  
          {/* Mobile Menu Button - Now shown by default on mobile */}
          <div className="flex items-center gap-4">
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="block md:hidden p-2"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? 
                <X size={24} className={textClass} /> : 
                <Menu size={24} className={textClass} />
              }
            </button>
            
            {/* Desktop CTA Buttons */}
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/signin" className={`${textClass} ${textHoverClass} transition-colors`}>
                Sign In
              </Link>
              <Link to="/signup" className={`${buttonBgClass} px-4 py-2 rounded-full transition-colors`}>
                Get Started Free
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Full Screen Mobile Menu */}
      <div 
        className={`fixed inset-0 bg-[#2828f1] z-40 transition-transform duration-300 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } md:hidden`}
      >
        <div className="flex flex-col h-full px-6 py-20">
          {/* Mobile Navigation Links */}
          <div className="flex flex-col space-y-8 text-center">
            <a 
              href="#features" 
              className="text-white text-3xl font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Features
            </a>
            <a 
              href="#pricing" 
              className="text-white text-3xl font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Pricing
            </a>
            <a 
              href="#about" 
              className="text-white text-3xl font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              About
            </a>
            <a 
              href="../contact" 
              className="text-white text-3xl font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </a>
            <a 
              href="/blog" 
              className="text-white text-3xl font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Blog
            </a>
          </div>

          {/* Mobile Menu Bottom CTAs */}
          <div className="mt-auto space-y-4">
            <Link
              to="/signin"
              className="block text-center text-white text-lg"
              onClick={() => setIsMenuOpen(false)}
            >
              Sign In
            </Link>
            <Link
              to="/signup"
              className="block w-full bg-[#AEFA98] text-gray-900 py-4 rounded-full text-center text-lg font-medium"
              onClick={() => setIsMenuOpen(false)}
            >
              Get Started Free
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;