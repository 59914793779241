import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { SidebarProvider, useSidebar } from './contexts/SidebarContext';
import SidebarMenu from './components/SidebarMenu';
import Footer from './components/Footer';
import Home from './components/Home';
import Inventory from './components/Inventory';
import SalesOrders from './components/SalesOrders';
import PurchaseOrders from './components/PurchaseOrders';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Welcome from './frontend/Welcome';
import Contacts from './components/XeroContacts';
import ValidateUsers from './components/ValidateUsers';
import CompanySwitcher from './components/CompanySwitcher';
import XeroCallback from './components/XeroCallback';
import MyAccount from './components/MyAccount';
import Payment from './components/Payment';
import Header from './components/layout/Header';
import BarcodeScanner from './components/BarcodeScanner';
/**
 * import { NotificationProvider } from './contexts/NotificationContext';
 * import { setupNotificationListeners } from './/services/notificationService';
 */


import { getApp } from "@firebase/app";
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import ProtectedRoute from './components/ProtectedRoute';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import SubscriptionError from './components/SubscriptionError';
import OrderDetails from './components/OrderDetails';

import BlogArchive from './frontend/BlogArchive';
import BlogSingle from './frontend/BlogSingle';
import ContactPage from './frontend/ContactPage';
import { HelmetProvider } from 'react-helmet-async';
import ItemDetail from './components/ItemDetail';

function PrivateRoute({ children, adminOnly = false }) {
  const { currentUser, userRole } = useAuth();
  if (!currentUser) return <Navigate to="/welcome" />;
  if (adminOnly && userRole !== 'admin') return <Navigate to="/signin" />;
  return children;
}

function MainContent({ children, isAuthenticated }) {
  const sidebarContext = useSidebar();
  const isExpanded = sidebarContext ? sidebarContext.isExpanded : false;
  
  // Only apply main-content classes if authenticated
  if (!isAuthenticated) {
    return children;
  }

  const mainContentClass = `main-content ${isExpanded ? 'expanded' : 'collapsed'}`;
  return <main className={mainContentClass}>{children}</main>;
}

function AuthenticatedContent({ children }) {
  return (
    <div className="app-container">
      {children}
    </div>
  );
}
function AppContent() {
  const { currentUser, loading, userRole } = useAuth();
  const [currentCompany, setCurrentCompany] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    let cleanupListeners;
    
    return () => {
      if (cleanupListeners) {
        cleanupListeners();
      }
    };
  }, [currentUser]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleCompanyChange = (companyId) => {
    setCurrentCompany(companyId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Separate Routes into components
  const content = (
    <Routes>
      {/* Public routes */}

        <Route path="/" element={!currentUser ? <Welcome /> : <Navigate to="/dashboard" />} />
        <Route path="/signin" element={!currentUser ? <SignIn /> : <Navigate to="/dashboard" />} />
        <Route path="/signup" element={!currentUser ? <SignUp /> : <Navigate to="/dashboard" />} />
        <Route path="/blog" element={<BlogArchive />} />
        <Route path="/blog/:slug" element={<BlogSingle />} />
        <Route path="/contact" element={<ContactPage />} />

      
      {/* Payment routes - rendered without header/footer */}
      <Route path="/payment" element={<Payment />} />
      <Route path="/subscription" element={<Payment />} />
      <Route path="/subscription/success" element={<SubscriptionSuccess />} />
      <Route path="/subscription/error" element={<SubscriptionError />} />
      
      {/* Protected routes */}
      <Route path="/dashboard" element={<PrivateRoute><ProtectedRoute><Home currentCompany={currentCompany} /></ProtectedRoute></PrivateRoute>} />
      <Route path="/inventory" element={<PrivateRoute><ProtectedRoute><Inventory currentCompany={currentCompany} /></ProtectedRoute></PrivateRoute>} />
      <Route 
        path="/inventory/:id" 
        element={
          <PrivateRoute>
            <ProtectedRoute>
              <ItemDetail currentCompany={currentCompany} />
            </ProtectedRoute>
          </PrivateRoute>
        } 
      />
      <Route 
        path="/scanner" 
        element={
          <PrivateRoute>
            <ProtectedRoute>
              <BarcodeScanner currentCompany={currentCompany} />
            </ProtectedRoute>
          </PrivateRoute>
        } 
      />
      <Route path="/sales-orders" element={<PrivateRoute><ProtectedRoute><SalesOrders currentCompany={currentCompany} /></ProtectedRoute></PrivateRoute>} />
      <Route path="/purchase-orders" element={<PrivateRoute><ProtectedRoute><PurchaseOrders currentCompany={currentCompany} /></ProtectedRoute></PrivateRoute>} />
      <Route path="/sales-orders/:orderId" element={<PrivateRoute><ProtectedRoute><OrderDetails type="sales" /></ProtectedRoute></PrivateRoute>} />
      <Route path="/purchase-orders/:orderId" element={<PrivateRoute><ProtectedRoute><OrderDetails type="purchase" /></ProtectedRoute></PrivateRoute>} />
      <Route path="/contacts" element={<PrivateRoute><ProtectedRoute><Contacts /></ProtectedRoute></PrivateRoute>} />
      <Route path="/my-account" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
      <Route path="/validate-users" element={<PrivateRoute adminOnly={true}><ValidateUsers /></PrivateRoute>} />
      <Route path="/xero-callback" element={<XeroCallback />} />
      
      {/* Catch all route */}
      <Route path="*" element={<Navigate to={currentUser ? "/dashboard" : "/"} />} />
    </Routes>
  );

  // Check if current route is a payment route
  const isPaymentRoute = window.location.pathname.includes('/payment') || 
                        window.location.pathname.includes('/subscription');

  // If it's a payment route, render content without wrapper
  if (isPaymentRoute) {
    return content;
  }

  // Otherwise, render with appropriate wrapper based on auth status // NotificationProvider was wrapped on 154 above MainContent
  return (
    <>
      {currentUser ? (
        <AuthenticatedContent>
         
            <MainContent isAuthenticated={true}>
              <Header toggleSidebar={toggleSidebar} />
              {content}
              <CompanySwitcher onCompanyChange={handleCompanyChange} />
            </MainContent>
            <Footer />
         
        </AuthenticatedContent>
      ) : (
        <MainContent isAuthenticated={false}>
                <HelmetProvider>
          {content}
           </HelmetProvider>
        </MainContent>
      )}
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;