import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, addDoc, getDocs, query, where, Timestamp} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import getCompanyIdForUser from '../utils/getCompanyIdForUser';
import { Edit, Save, X, DollarSign, Package, Calendar, ShoppingCart } from 'lucide-react'
import { Barcode } from 'lucide-react';
import JsBarcode from 'jsbarcode';
import { jsPDF } from 'jspdf';

const LoadingSpinner = () => (
  <div className="flex justify-center items-center min-h-screen">
    <div className="text-xl">Loading item details...</div>
  </div>
);

const ItemDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [item, setItem] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [orderHistory, setOrderHistory] = useState({ sales: [], purchases: [] });

  useEffect(() => {
    const fetchCompanyId = async () => {
      if (currentUser) {
        const id = await getCompanyIdForUser(currentUser.uid);
        setCompanyId(id);
      }
    };
    fetchCompanyId();
  }, [currentUser]);

  useEffect(() => {
    const fetchItem = async () => {
      if (!id || !companyId) return;

      try {
        const docRef = doc(db, 'inventory', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const itemData = { 
            id: docSnap.id, 
            ...docSnap.data(),
            description: docSnap.data().description || '',
            barcode: docSnap.data().barcode || '' // Ensure barcode exists
          };
          
          if (itemData.companyId === companyId) {
            setItem(itemData);
            fetchComments();
            fetchOrderHistory(itemData);
          } else {
            navigate('/inventory');
          }
        } else {
          navigate('/inventory');
        }
      } catch (error) {
        console.error('Error fetching item:', error);
      } finally {
        setLoading(false);
      }
    };

    if (companyId) {
      fetchItem();
    }
  }, [id, companyId, navigate]);

  // Add this function inside your ItemDetail component
const generateBarcodePDF = () => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  
  // Get the code to use (barcode or SKU)
  const barcodeValue = item.barcode || item.sku;
  
  // Generate barcode
  JsBarcode(canvas, barcodeValue, {
    format: "CODE128",
    width: 2,
    height: 50,
    displayValue: true,
    fontSize: 12,
    margin: 10
  });

  // Create PDF (4 inch width = ~102mm)
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: [102, 51] // 4 inches x 2 inches
  });

  // Add the item name
  pdf.setFontSize(12);
  pdf.text(item.name + `   $${parseFloat(item.sell_price).toFixed(2)}`, 5, 10);

  // Add the barcode
  const barcodeImage = canvas.toDataURL('image/png');
  pdf.addImage(barcodeImage, 'PNG', 5, 18, 92, 25);

  // Save the PDF
  pdf.save(`barcode-${item.name.replace(/\s+/g, '-')}.pdf`);
};

  const fetchOrderHistory = async (itemData) => {
    try {
      // Fetch sales orders containing this item
      const salesQuery = query(
        collection(db, 'salesOrders'),
        where('companyId', '==', companyId)
      );
      const salesDocs = await getDocs(salesQuery);
      const salesOrders = salesDocs.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(order => order.items.some(item => item.id === itemData.id))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // Fetch purchase orders containing this item
      const purchaseQuery = query(
        collection(db, 'purchaseOrders'),
        where('companyId', '==', companyId)
      );
      const purchaseDocs = await getDocs(purchaseQuery);
      const purchaseOrders = purchaseDocs.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(order => order.items.some(item => item.id === itemData.id))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setOrderHistory({
        sales: salesOrders,
        purchases: purchaseOrders
      });
    } catch (error) {
      console.error('Error fetching order history:', error);
    }
  };

  const fetchComments = async () => {
    try {
      const commentsRef = collection(db, `inventory/${id}/comments`);
      const commentsSnapshot = await getDocs(commentsRef);
      const commentsList = commentsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsList.sort((a, b) => b.timestamp - a.timestamp));
    } catch (err) {
      console.error('Error fetching comments:', err);
    }
  };

  const addComment = async () => {
    if (!newComment.trim()) return;

    try {
      const commentsRef = collection(db, `inventory/${id}/comments`);
      await addDoc(commentsRef, {
        text: newComment,
        userId: currentUser.uid,
        userName: currentUser.displayName || currentUser.email,
        timestamp: Timestamp.now()
      });
      setNewComment('');
      fetchComments();
    } catch (err) {
      console.error('Error adding comment:', err);
    }
  };

  const handleFieldEdit = (fieldName) => {
    setEditingField(fieldName);
  };

  const handleFieldSave = async (fieldName) => {
    try {
      const itemRef = doc(db, 'inventory', id);
      const updates = {
        [fieldName]: fieldName === 'quantity' ? parseInt(item[fieldName]) :
                    (fieldName === 'cost_price' || fieldName === 'sell_price') ? parseFloat(item[fieldName]) :
                    item[fieldName]
      };
      
      await updateDoc(itemRef, updates);
      setEditingField(null);
    } catch (error) {
      console.error('Error updating field:', error);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setItem(prev => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const renderEditableField = (fieldName, label, type = "text", step = null) => {
    const isEditing = editingField === fieldName;
    const value = item[fieldName];

    return (
      <div className="mb-6 bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-all">
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-semibold text-gray-700">{label}</h3>
          {!isEditing && (
            <button
              onClick={() => handleFieldEdit(fieldName)}
              className="flex items-center gap-2 text-blue-600 hover:text-blue-800 text-sm"
            >
              <Edit size={16} />
              Click to edit
            </button>
          )}
        </div>
        <div className="flex items-center gap-2">
          {isEditing ? (
            <>
              {type === 'textarea' ? (
                <textarea
                  value={value}
                  onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                  className="w-full p-2 border rounded"
                  rows="4"
                />
              ) : (
                <input
                  type={type}
                  value={value}
                  step={step}
                  onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                  className="w-full p-2 border rounded"
                />
              )}
              <button
                onClick={() => handleFieldSave(fieldName)}
                className="p-2 bg-green-500 text-white rounded hover:bg-green-600"
                title="Save"
              >
                <Save size={16} />
              </button>
              <button
                onClick={() => setEditingField(null)}
                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                title="Cancel"
              >
                <X size={16} />
              </button>
            </>
          ) : (
            <div className="w-full p-2 bg-white rounded border">
              {type === 'number' && (fieldName === 'cost_price' || fieldName === 'sell_price') 
                ? `$${parseFloat(value).toFixed(2)}`
                : value}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderOrderHistory = () => (
    <div className="mt-8 space-y-6">
      <h2 className="text-xl font-bold mb-4">Order History</h2>
      
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
          <ShoppingCart size={20} />
          Sales Orders
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Order ID</th>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">Quantity</th>
                <th className="px-4 py-2 text-left">Price</th>
                <th className="px-4 py-2 text-left">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orderHistory.sales.map(order => {
                const orderItem = order.items.find(item => item.id === id);
                return (
                  <tr key={order.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2">
                      <a 
                        href={`/sales-orders/${order.id}`}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {order.orderId}
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">{orderItem.quantity}</td>
                    <td className="px-4 py-2">${orderItem.price.toFixed(2)}</td>
                    <td className="px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        order.status === 'completed' ? 'bg-green-100 text-green-800' :
                        order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {order.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
              {orderHistory.sales.length === 0 && (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                    No sales orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4 flex items-center gap-2">
          <Package size={20} />
          Purchase Orders
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Order ID</th>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">Quantity</th>
                <th className="px-4 py-2 text-left">Price</th>
                <th className="px-4 py-2 text-left">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {orderHistory.purchases.map(order => {
                const orderItem = order.items.find(item => item.id === id);
                return (
                  <tr key={order.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2">
                      <a 
                        href={`/purchase-orders/${order.id}`}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {order.orderId}
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">{orderItem.quantity}</td>
                    <td className="px-4 py-2">${orderItem.price.toFixed(2)}</td>
                    <td className="px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        order.status === 'completed' ? 'bg-green-100 text-green-800' :
                        order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {order.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
              {orderHistory.purchases.length === 0 && (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                    No purchase orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  if (loading || !item) {
    return <LoadingSpinner />;
  }

  return (
    <div className="max-w-6xl mx-auto mt-8 p-6">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-6">
        <h1 className="text-2xl font-bold mb-6">{item.name}</h1>
        
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            {renderEditableField('sku', 'SKU')}
            
            {/* Replace your existing barcode field with this */}
<div className="mb-6 bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-all">
  <div className="flex items-center justify-between mb-2">
    <h3 className="font-semibold text-gray-700">Barcode</h3>
    <div className="flex items-center">
      {!editingField && (
        <>
          <button
            onClick={generateBarcodePDF}
            className="mr-4 p-2 bg-blue-500 rounded hover:bg-blue-600 flex items-center gap-2"
          >
            <Barcode size={16} />
            Generate Label
          </button>
          <button
            onClick={() => handleFieldEdit('barcode')}
            className="flex items-center gap-2 text-blue-600 hover:text-blue-800 text-sm"
          >
            <Edit size={16} />
            Click to edit
          </button>
        </>
      )}
    </div>
  </div>
  <div className="flex items-center gap-2">
    {editingField === 'barcode' ? (
      <>
        <input
          type="text"
          value={item.barcode}
          onChange={(e) => handleFieldChange('barcode', e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Enter barcode or leave empty to use SKU"
        />
        <button
          onClick={() => handleFieldSave('barcode')}
          className="p-2 bg-green-500 rounded hover:bg-green-600"
          title="Save"
        >
          <Save size={16} />
        </button>
        <button
          onClick={() => setEditingField(null)}
          className="p-2 bg-red-500  rounded hover:bg-red-600"
          title="Cancel"
        >
          <X size={16} />
        </button>
      </>
    ) : (
      <div className="w-full p-2 bg-white rounded border">
        {item.barcode || 'Using SKU as barcode'}
      </div>
    )}
  </div>
</div>

            {renderEditableField('category', 'Category')}
            {renderEditableField('quantity', 'Quantity', 'number')}
            {renderEditableField('location', 'Location')}
          </div>
          
          <div>
            {renderEditableField('cost_price', 'Cost Price', 'number', '0.01')}
            {renderEditableField('sell_price', 'Sell Price', 'number', '0.01')}
            <div className="mb-6 bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold text-gray-700 mb-2">Margin</h3>
              <div className="p-2 bg-white rounded border">
                ${(item.sell_price - item.cost_price).toFixed(2)} 
                ({((item.sell_price - item.cost_price) / item.cost_price * 100).toFixed(2)}%)
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          {renderEditableField('description', 'Description', 'textarea')}
        </div>
      </div>

      {renderOrderHistory()}

      <div className="bg-white rounded-lg shadow-lg p-6 mt-6">
        <h2 className="text-xl font-semibold mb-4">Comments</h2>
        <div className="mb-4">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="w-full p-2 border rounded mb-2"
            rows="3"
          />
          <button
            onClick={addComment}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Add Comment
          </button>
        </div>

        <div className="space-y-4">
          {comments.map(comment => (
            <div key={comment.id} className="border-b pb-4">
              <div className="flex justify-between text-sm text-gray-600">
                <span className="font-medium">{comment.userName}</span>
                <span>{comment.timestamp.toDate().toLocaleString()}</span>
              </div>
              <p className="mt-2">{comment.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;