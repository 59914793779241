import React from 'react';
import { 
  ArrowUpRight, 
  Cloud, 
  BarChart3, 
  Building2, 
  RefreshCcw, 
  UserCheck, 
  Wallet
} from 'lucide-react';

const FeatureCard = ({ feature, benefit, icon: Icon, colorClass }) => (
  <div className={`p-8 rounded-2xl ${colorClass} transition-transform hover:scale-[1.02]`}>
    <div className="mb-6">
      <Icon className="w-8 h-8" />
    </div>
    <div>
      <h3 className="text-lg font-semibold mb-4">
        {feature}
      </h3>
      <p className="text-sm opacity-80">
        {benefit}
      </p>
    </div>
  </div>
);

const FeaturesBenefitsSection = () => {
  const features = [
    {
      feature: "Integrates with Xero (and WooCommerce/Shopify coming soon)",
      benefit: "Save time and avoid double data entry by syncing inventory, sales, and accounting seamlessly.",
      icon: RefreshCcw,
      colorClass: "bg-[#F1FFEF] text-gray-800"
    },
    {
      feature: "More robust than a spreadsheet but simpler than an ERP",
      benefit: "Manage inventory with ease and confidence without complex systems or clunky spreadsheets.",
      icon: BarChart3,
      colorClass: "bg-[#F5F5FF] text-gray-800"
    },
    {
      feature: "Designed specifically for small businesses",
      benefit: "Tailored to your needs: simple, affordable, and scalable for growing businesses.",
      icon: Building2,
      colorClass: "bg-[#FFF5F5] text-gray-800"
    },
    {
      feature: "Cloud-based with real-time updates",
      benefit: "Stay in control with accurate, up-to-date inventory tracking from anywhere.",
      icon: Cloud,
      colorClass: "bg-[#F1FFEF] text-gray-800"
    },
    {
      feature: "User-friendly interface",
      benefit: "Spend less time learning and more time running your business with an intuitive platform.",
      icon: UserCheck,
      colorClass: "bg-[#F5F5FF] text-gray-800"
    },
    {
      feature: "Affordable pricing tailored for small businesses",
      benefit: "Professional inventory management at a small-business-friendly price.",
      icon: Wallet,
      colorClass: "bg-[#FFF5F5] text-gray-800"
    }
  ];

  return (
    <section className="bg-white py-20" id='features'>
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
          Bye Bye 👋 
          Spreadsheets
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-left">
          Stop wrestling with Spreadsheets. Start scaling your business. Oneventory gives you the inventory control you need, without the overhead you don't.
        </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((item, index) => (
            <FeatureCard
              key={index}
              feature={item.feature}
              benefit={item.benefit}
              icon={item.icon}
              colorClass={item.colorClass}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesBenefitsSection;