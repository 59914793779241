import React from 'react';
import {
  Sparkle,
  Heart,
  Target,
  Settings,
  Users,
  ArrowUpRight
} from 'lucide-react';

const ValueCard = ({ icon: Icon, title, description }) => (
  <div className="bg-gray-50 rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow">
    <Icon className="w-6 h-6 text-[#2828f1] mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600 text-sm">{description}</p>
  </div>
);

const BenefitTag = ({ children }) => (
  <div className="bg-[#F1FFEF] text-gray-800 px-4 py-2 rounded-full text-sm font-medium inline-flex items-center gap-2">
    <Sparkle className="w-4 h-4" />
    {children}
  </div>
);

const MissionUSPSection = () => {
  const values = [
    {
      icon: Heart,
      title: "Simplicity at the Core",
      description: "Technology should make life easier, not harder."
    },
    {
      icon: Target,
      title: "Customer-Centric Innovation",
      description: "Inspired by user needs, with integrations like Xero, WooCommerce, and Shopify."
    },
    {
      icon: Settings,
      title: "Efficiency for Growth",
      description: "Save time, reduce errors, and unlock productivity."
    },
    {
      icon: Users,
      title: "Partnership, Not Just Software",
      description: "We're invested in our customers' success."
    }
  ];

  return (
    <section className="bg-white py-20 px-4 md:px-8 lg:px-16 relative overflow-hidden" id='about'>
      {/* Origin Story */}
      <div className="max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-16 items-center mb-20">
          <div>
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Our Story
            </h2>
            <p className="text-gray-600 text-lg mb-8">
              Our journey began with a simple yet powerful realization: small businesses deserve better tools to manage their inventory. Too often, they're caught between two extremes—spreadsheets that are too basic and cumbersome, and ERPs that are overly complex and expensive.
            </p>
            <p className="text-gray-600 text-lg">
              We envisioned a solution tailored specifically for small businesses, offering the right balance of functionality, affordability, and simplicity.
            </p>
          </div>
          <div className="space-y-4">
            <BenefitTag>Easy to Use</BenefitTag>
            <BenefitTag>Scalable for Growth</BenefitTag>
            <BenefitTag>Seamlessly Integrated</BenefitTag>
            <BenefitTag>Cost-Effective</BenefitTag>
          </div>
        </div>

        {/* USP Box */}
        <div className="bg-[#2828f1] rounded-3xl p-8 md:p-12 mb-20">
          <div className="flex items-start justify-between mb-8">
            <h3 className="text-2xl md:text-3xl font-bold text-white max-w-2xl">
              "Inventory management made simple: Bigger than a spreadsheet, but not a puzzle like an ERP."
            </h3>
            <ArrowUpRight className="w-8 h-8 text-[#AEFA98]" />
          </div>
          <p className="text-white/90 text-lg">
            Our mission is to empower small businesses to thrive by simplifying inventory management. We're here to take the guesswork out of tracking stock, streamline operations, and provide a platform that grows with businesses—no matter their size or ambition.
          </p>
        </div>

        {/* Values Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {values.map((value, index) => (
            <ValueCard key={index} {...value} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MissionUSPSection;