import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

const ShopifyConnect = () => {
  const { currentUser } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [shopifyStore, setShopifyStore] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkConnection();
  }, [currentUser]);

  const checkConnection = async () => {
    try {
      const companyDoc = await getDoc(doc(db, 'companies', currentUser.companyId));
      if (companyDoc.exists()) {
        const shopifyData = companyDoc.data().shopifyIntegration || {};
        setIsConnected(!!shopifyData.accessToken);
        setShopifyStore(shopifyData.shopUrl || '');
      }
    } catch (err) {
      setError('Failed to check Shopify connection');
    } finally {
      setLoading(false);
    }
  };

  const connectToShopify = async () => {
    if (!shopifyStore) {
      setError('Please enter your Shopify store URL');
      return;
    }

    // Format store URL
    const formattedStore = shopifyStore.replace(/^https?:\/\//, '')
      .replace(/\/$/, '');

    // Save initial connection state
    await setDoc(doc(db, 'companies', currentUser.companyId), {
      shopifyIntegration: {
        shopUrl: formattedStore,
        status: 'connecting',
        createdAt: new Date().toISOString()
      }
    }, { merge: true });

    // Redirect to Shopify OAuth
    const shopifyConfig = {
      clientId: process.env.REACT_APP_SHOPIFY_CLIENT_ID,
      redirectUri: `${window.location.origin}/shopify/callback`,
      scopes: 'read_products,write_products,read_inventory,write_inventory'
    };

    window.location.href = `https://${formattedStore}/admin/oauth/authorize?` +
      `client_id=${shopifyConfig.clientId}&` +
      `redirect_uri=${encodeURIComponent(shopifyConfig.redirectUri)}&` +
      `scope=${encodeURIComponent(shopifyConfig.scopes)}`;
  };

  const disconnectShopify = async () => {
    try {
      await setDoc(doc(db, 'companies', currentUser.companyId), {
        shopifyIntegration: {
          shopUrl: null,
          accessToken: null,
          status: 'disconnected',
          disconnectedAt: new Date().toISOString()
        }
      }, { merge: true });
      
      setIsConnected(false);
      setShopifyStore('');
    } catch (err) {
      setError('Failed to disconnect from Shopify');
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-4 border rounded-lg">
      <h3 className="text-lg font-semibold mb-4">Shopify Integration</h3>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      
      {isConnected ? (
        <div>
          <div className="flex items-center mb-4">
            <span className="text-green-500 mr-2">✓</span>
            <span>Connected to {shopifyStore}</span>
          </div>
          <button
            onClick={disconnectShopify}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Disconnect from Shopify
          </button>
        </div>
      ) : (
        <div>
          <input
            type="text"
            value={shopifyStore}
            onChange={(e) => setShopifyStore(e.target.value)}
            placeholder="your-store.myshopify.com"
            className="w-full p-2 border rounded mb-4"
          />
          <button
            onClick={connectToShopify}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Connect to Shopify
          </button>
        </div>
      )}
    </div>
  );
};

export default ShopifyConnect;