import React from 'react';
import ResponsiveNav from './layout/ResponsiveNav';
import { Home, PackageOpen, ShoppingCart, Truck, NotebookTabs, User} from 'lucide-react';

function Footer() {

  const menuItems = [
    { path: '/', icon: <Home />, text: 'Home' },
    { path: '/inventory', icon: <PackageOpen />, text: 'Inventory' },
    { path: '/sales-orders', icon: <ShoppingCart />, text: 'Sales Orders' },
    { path: '/purchase-orders', icon: <Truck />, text: 'Purchase Orders' },
    { path: '/contacts', icon: <NotebookTabs />, text: 'Contacts' },
    { path: '/my-account', icon: <User />, text: 'My Account' },
  ];

  return (
    <footer className="footer footer-loggedin">
      <div className="container">
        <ResponsiveNav menuItems={menuItems} />
      </div>
    </footer>
  );
}

export default Footer;