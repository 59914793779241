import React, { useState, useEffect } from 'react';
import { ArrowRight, Sparkle } from 'lucide-react';
import Nav from './Nav';
import { Link } from 'react-router-dom';

const Blob = ({ className }) => (
  <div
    className={`absolute bg-green-700/50 rounded-full blur-3xl animate-blob ${className}`}
    style={{
      animation: `blob-bounce 7s infinite ${Math.random() * 2}s`
    }}
  />
);

const HeroSection = () => {
  const titles = [
    "Super Simple",
    "Real-time",
    "Integrated",
    "Cloud Based"
  ];
  
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentTitleIndex((prev) => (prev + 1) % titles.length);
        setIsAnimating(false);
      }, 100);
    }, 1700);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="min-h-screen bg-[#2828f1] flex flex-col relative overflow-hidden">
      <Blob className="w-96 h-96 top-0 left-20" />
      <Blob className="w-96 h-96 top-40 right-20 animation-delay-2000" />
      <Blob className="w-96 h-96 bottom-20 left-40 animation-delay-4000" />
      
      <Nav />
      
      <div className="flex-1 flex items-center justify-center relative z-10">
        <div className="max-w-7xl mx-auto px-4 md:px-8 lg:px-16 w-full text-center">
          <div className="inline-flex items-center space-x-2 mb-8 bg-blue-700/30 px-6 py-3 rounded-full">
            <Sparkle className="w-5 h-5 text-[#AEFA98]" />
            <h1 className="text-white/90">Easy-to-use inventory software</h1>
            <div className="h-[1px] w-12 bg-white/20"></div>
          </div>

          <div className="flex flex-col items-center justify-center mb-8">
            <h2 className="text-4xl sm:text-2xl md:text-7xl lg:text-8xl font-bold text-white inline-flex flex-wrap justify-center">
              <span className="relative h-[1.2em] overflow-hidden inline-flex items-center mr-4">
                <span 
                  className={`transition-transform duration-200 ${
                    isAnimating ? 'translate-y-full opacity-0' : 'translate-y-0 opacity-100'
                  }`}
                >
                  {titles[currentTitleIndex]}
                </span>
              </span>
              <span className="inline-flex items-center">Inventory 🚀</span>
            </h2>
          </div>

          <p className="text-white/90 text-base sm:text-lg md:text-xl mb-8 mx-auto max-w-xl px-4">
            For businesses ready to graduate from spreadsheets,
            but not looking for ERP complexity.
          </p>

          <Link
            to="/signup"
            className="bg-[#AEFA98] text-gray-900 px-6 py-3 rounded-full hover:bg-[#9DE589] transition-colors inline-flex items-center space-x-2 mb-16"
          >
            <span>Start our Free Trial</span>
            <ArrowRight className="w-5 h-5" />
          </Link>
        </div>
      </div>

      <style jsx>{`
        @keyframes blob-bounce {
          0%, 100% { transform: translate(0, 0) scale(1); }
          25% { transform: translate(25px, 25px) scale(1.1); }
          50% { transform: translate(-25px, 15px) scale(0.9); }
          75% { transform: translate(15px, -25px) scale(1.05); }
        }
        .animate-blob {
          animation: blob-bounce 7s infinite;
        }
        .animation-delay-2000 {
          animation-delay: 2s;
        }
        .animation-delay-4000 {
          animation-delay: 4s;
        }
      `}</style>
    </section>
  );
};

export default HeroSection;