import React, { useState, useEffect } from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Nav from './componentry/Nav';
import Footer from './componentry/Footer';
import { Helmet } from 'react-helmet-async';
import { slugify } from '../utils/slugify';

const client = createClient({
  space: 'q8tyy7apys6n',
  accessToken: 'bcvbgpSk2tlxz4rDLs2nKRiLUVPHnXEUpKDzqcq28O4',
  environment: 'master',
});

const BlogSingle = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recommendedPosts, setRecommendedPosts] = useState([]);

  const getTextExcerpt = (richText, maxLength = 160) => {
    if (!richText || !richText.content) return '';
    
    const text = richText.content
      .filter(item => item.nodeType === 'paragraph')
      .map(item => 
        item.content
          .filter(content => content.nodeType === 'text')
          .map(content => content.value)
          .join('')
      )
      .join(' ')
      .trim();

    return text.length > maxLength 
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'blogPage',
          'fields.title[match]': slug.replace(/-/g, ' '),
          limit: 1
        });

        if (response.items.length === 0) {
          setError('Post not found');
          setLoading(false);
          return;
        }

        const post = response.items[0];
        setPost(post);
        
        if (post.fields.recommendedPosts) {
          const recommended = await Promise.all(
            post.fields.recommendedPosts.map(p => client.getEntry(p.sys.id))
          );
          setRecommendedPosts(recommended);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching post:', err);
        setError('Failed to fetch blog post');
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#F5F5FF] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#2828f1]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#F5F5FF] flex items-center justify-center text-gray-900">
        {error}
      </div>
    );
  }

  if (!post) return null;

  // Only compute these values after we know we have a post
  const description = getTextExcerpt(post.fields.body);
  const imageUrl = post?.fields?.image?.fields?.file?.url || 'https://oneventory.app/ONEV_nobg.png';
  const publishDate = new Date(post.sys.createdAt).toISOString();

  // Redirect if the slug doesn't match the canonical form
  if (slugify(post.fields.title) !== slug) {
    return <Navigate to={`/blog/${slugify(post.fields.title)}`} replace />;
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          src={node.data.target.fields.file.url}
          alt={node.data.target.fields.description}
          className="my-8 rounded-lg max-w-full h-auto"
        />
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">{children}</h2>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-gray-600 mb-6 leading-relaxed">{children}</p>
      ),
    }
  };

  return (
    <div className="bg-[#F5F5FF] min-h-screen">
      <Helmet>
        <title>{`${post.fields.title} | Oneventory`}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://oneventory.app/blog/${slug}`} />
        <meta property="og:title" content={`${post.fields.title} | Oneventory Blog`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={`https://oneventory.app/blog/${slug}`} />
        <meta name="twitter:title" content={`${post.fields.title} | Oneventory Blog`} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
        <meta property="article:published_time" content={publishDate} />
        <meta property="article:publisher" content="https://oneventory.app" />
        <link rel="canonical" href={`https://oneventory.app/blog/${slug}`} />
      </Helmet>

      <Nav />

      <article className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-40">
        <header className="text-center mb-12">
          {post.fields.image && (
            <div className="mb-8 rounded-2xl overflow-hidden">
              <img
                src={post.fields.image.fields.file.url}
                alt={post.fields.title}
                className="w-full h-auto"
              />
            </div>
          )}
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            {post.fields.title}
          </h1>
          <div className="text-gray-600">
            {new Date(post.sys.createdAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </div>
        </header>

        <div className="prose prose-lg max-w-none">
          {documentToReactComponents(post.fields.body, options)}
        </div>

        {recommendedPosts.length > 0 && (
          <div className="mt-16 pt-16 border-t border-gray-200">
            <h2 className="text-2xl font-bold text-gray-900 mb-8">
              Recommended Posts
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {recommendedPosts.map((post) => (
                <Link
                  key={post.sys.id}
                  to={`/blog/${slugify(post.fields.title)}`}
                  className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow"
                >
                  {post.fields.image && (
                    <div className="aspect-video w-full overflow-hidden">
                      <img
                        src={post.fields.image.fields.file.url}
                        alt={post.fields.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      {post.fields.title}
                    </h3>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </article>
      <Footer />
    </div>
  );
};

export default BlogSingle;