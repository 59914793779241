import React, { useEffect } from 'react';
import { FileQuestion, MessageCircle, Newspaper } from 'lucide-react';
import Nav from './componentry/Nav';
import Footer from './componentry/Footer';

const ContactPage = () => {
  useEffect(() => {
    // Load HubSpot script
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    document.head.appendChild(script);

    script.addEventListener('load', () => {
      // Create HubSpot form after script loads
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "48743877",
          formId: "6c7069b3-7e51-4cd5-8c6a-4119c01b5028",
          target: '#hubspot-form-container'
        });
      }
    });

    // Cleanup function
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="min-h-screen bg-[#F5F5FF]">
      <Nav />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-20">
        {/* Header Section */}
        <div className="max-w-2xl">
          <div className="text-[#2828f1] font-medium mb-4">CONTACT US</div>
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-4">
            We'd love to hear <br />
            from you.
          </h1>
          <p className="text-gray-600 text-lg">
            While we're experts at inventory management,
            there are easier ways to get in touch.
          </p>
        </div>

        {/* Contact Form and Cards Grid */}
        <div className="mt-16 grid lg:grid-cols-2 gap-16">
          {/* HubSpot Form Container */}
          <div className="bg-white rounded-2xl p-8 shadow-sm">
            <div id="hubspot-form-container"></div>
          </div>

          {/* Contact Cards */}
          <div className="space-y-6">
            <div className="bg-white rounded-2xl p-8 shadow-sm">
              <div className="p-3 bg-[#F5F5FF] rounded-lg w-fit mb-6">
                <FileQuestion className="w-6 h-6 text-[#2828f1]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Check our documentation
              </h3>
              <p className="text-gray-600 mb-6">
                Find answers to common questions and learn how to make the most of your inventory management.
              </p>
              <a href="/docs" className="text-[#2828f1] font-medium hover:text-[#2020d0] transition-colors">
                Visit Documentation →
              </a>
            </div>

            <div className="bg-white rounded-2xl p-8 shadow-sm">
              <div className="p-3 bg-[#F5F5FF] rounded-lg w-fit mb-6">
                <Newspaper className="w-6 h-6 text-[#2828f1]" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Latest Updates
              </h3>
              <p className="text-gray-600 mb-6">
                Stay up to date with our latest features, integrations, and inventory management tips.
              </p>
              <a href="/blog" className="text-[#2828f1] font-medium hover:text-[#2020d0] transition-colors">
                Visit our Blog →
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;